export const languages = {
    "auto": {
        locale: 'auto',
        Submit: 'Submit'
    },
    "ar": {
        locale: 'Arabic',
        Submit: 'يُقدِّم'
    },
    "bg": {
        locale: 'Bulgarian (Bulgaria)',
        Submit: 'Изпращане'
    },
    "cs": {
        locale: 'Czech (Czech Republic)',
        Submit: 'Předložit'
    },
    "da": {
        locale: 'Danish (Denmark)',
        Submit: 'Indsend'
    },
    "de": {
        locale: 'German (Germany)',
        Submit: 'Einreichen'
    },
    "el": {
        locale: 'Greek (Greece)',
        Submit: 'υποβάλλουν'
    },
    "en": {
        locale: 'English',
        Submit: 'Submit'
    },
    "en-GB": {
        locale: 'English (United Kingdom)',
        Submit: 'Submit'
    },
    "es": {
        locale: 'Spanish (Spain)',
        Submit: 'Entregar'
    },
    "es-419": {
        locale: 'Spanish (Latin America)',
        Submit: 'Comentarios'
    },
    "et": {
        locale: 'Estonian (Estonia)',
        Submit: 'Esitada'
    },
    "fi": {
        locale: 'Finnish (Finland)',
        Submit: 'Lähetä'
    },
    "fil": {
        locale: 'Filipino (Philipines)',
        Submit: 'Ipasa'
    },
    "fr": {
        locale: 'French (France)',
        Submit: 'Envoyez'
    },
    "fr-CA": {
        locale: 'French (Canada)',
        Submit: 'Soumettre'
    },
    "he": {
        locale: 'Hebrew (Israel)',
        Submit: 'שלח'
    },
    "hr": {
        locale: 'Croatian (Croatia)',
        Submit: 'podnijeti'
    },
    "hu": {
        locale: 'Hungarian (Hungary)',
        Submit: 'Beküldés'
    },
    "id": {
        locale: 'Indonesian (Indonesia)',
        Submit: 'Kirim'
    },
    "it": {
        locale: 'Italian (Italy)',
        Submit: 'Invia'
    },
    "ja": {
        locale: 'Japanese (Japan)',
        Submit: '送信'
    },
    "ko": {
        locale: 'Korean (Korea)',
        Submit: '제출하다'
    },
    "lt": {
        locale: 'Lithuanian (Lithuania)',
        Submit: 'Pateikti'
    },
    "lv": {
        locale: 'Latvian (Latvia)',
        Submit: 'Iesniegt'
    },
    "ms": {
        locale: 'Malay (Malaysia)',
        Submit: 'Serahkan'
    },
    "mt": {
        locale: 'Maltese (Malta)',
        Submit: 'Issottometti'
    },
    "nb": {
        locale: 'Norwegian Bokmål',
        Submit: 'Sende inn'
    },
    "nl": {
        locale: 'Dutch (Netherlands)',
        Submit: 'Indienen'
    },
    "pl": {
        locale: 'Polish (Poland)',
        Submit: 'Składać'
    },
    "pt-BR": {
        locale: 'Portuguese (Brazil)',
        Submit: 'Enviar'
    },
    "pt": {
        locale: 'Portuguese (Brazil)',
        Submit: 'Enviar'
    },
    "ro": {
        locale: 'Romanian (Romania)',
        Submit: 'Trimite'
    },
    "ru": {
        locale: 'Russian (Russia)',
        Submit: 'Представлять на рассмотрение'
    },
    "sk": {
        locale: 'Slovak (Slovakia)',
        Submit: 'Predložiť'
    },
    "sl": {
        locale: 'Slovenian (Slovenia)',
        Submit: 'Predložiti'
    },
    "sv": {
        locale: 'Swedish (Sweden)',
        Submit: 'Skicka in'
    },
    "th": {
        locale: 'Thai (Thailand)',
        Submit: 'ส่ง'
    },
    "tr": {
        locale: 'Turkish (Turkey)',
        Submit: 'Göndermek'
    },
    "vi": {
        locale: 'Vietnamese (Vietnam)',
        Submit: 'Nộp'
    },
    "zh": {
        locale: 'Chinese Simplified (China)',
        Submit: '提交'
    },
    "zh-HK": {
        locale: 'Chinese Traditional (Hong Kong)',
        Submit: '提交'
    },
    "zh-TW": {
        locale: 'Chinese Traditional (Taiwan)',
        Submit: '提交'
    }
};
