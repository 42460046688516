import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import { properties } from "../assets/properties";
import { languages } from "../assets/languages";
import { env } from '../env';
import Turnstile from "react-turnstile";
import BraintreePayPalComponent from "./BraintreePayPalComponent";

const ResponseStripeIframe = ({ isTemplate, captchaAction, addressLine1, addressLine2, postCode, country, returnUrl, intentId, region, currency, reference, locale, city, state, email, customerName, phone, paymentReference, isFutureUsageSet, amount, paypalClientToken, intentType, paypalCustomerId }) => {
    const baseURL = env.REACT_APP_BASE_URL;
    const inlineBaseURL = env.REACT_APP_INLINE_BASE_URL;
    const turnstileSiteKey = env.REACT_APP_TURNSTILE_SITE_KEY;
    const isCaptchaEnable = env.REACT_APP_CAPTCHA_ENABLE === true.toString();
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [showSubmitBtn, setShowSubmitBtn] = useState(false);
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [captchaToken, setCaptchaToken] = useState();
    const [showTurnstileLoading, setShowTurnstileLoading] = useState(false);
    const [showTurnstileError, setShowTurnstileError] = useState(false);
    const [declineRateLimitCount, setDeclineRateLimitCount] = useState(0);
    const [isAddressCaptured, setIsAddressCaptured] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("");
    let captchaRetryAttemptCount = 3;
    const isRedirect = isTemplate ? 'always' : 'if_required';
    let error, id;

    useEffect(() => {
        if (!stripe) {
            return;
        }
    }, [stripe]);

    const post = (path, params, method = 'post', isSuccess) => {
        const form = document.createElement('form');
        form.method = method;
        if (isTemplate) {
            form.target = "_parent";
        } else {
            form.target = "_self";
        }
        form.action = path;
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        if (!isSuccess) {
            hiddenField.name = properties.intentErrorHiddenKey;
            hiddenField.value = params;
        } else {
            hiddenField.name = intentId.startsWith("pi_") ? 'payment_intent' : 'setup_intent';
            hiddenField.value = intentId;
        }
        const hiddenRegionField = document.createElement('input');
        hiddenRegionField.type = 'hidden';
        hiddenRegionField.name = 'region';
        hiddenRegionField.value = region;
        const hiddenCurrencyField = document.createElement('input');
        hiddenCurrencyField.type = 'hidden';
        hiddenCurrencyField.name = 'currency';
        hiddenCurrencyField.value = currency;
        if (reference) {
            const hiddenRefField = document.createElement('input');
            hiddenRefField.type = 'hidden';
            hiddenRefField.name = 'ref';
            hiddenRefField.value = reference;
            form.appendChild(hiddenRefField);
        }
        form.appendChild(hiddenField);
        form.appendChild(hiddenCurrencyField);
        form.appendChild(hiddenRegionField);
        document.body.appendChild(form);
        form.submit();
    }
    const handleStripeSubmit = async () => {
        setShowCaptcha(false);
        let confirmParam = {
            elements,
            confirmParams: {
                return_url: returnUrl,
                payment_method_data: {
                    billing_details: {
                        address: {
                            // country: null,
                            city: (city === undefined || city === 'undefined' || city === '' || city == null) ? null : city,
                            country: (country === undefined || country === 'undefined' || country === '' || country == null) ? null : country,
                            line1: (addressLine1 === undefined || addressLine1 === 'undefined' || addressLine1 === '' || addressLine1 == null) ? null : addressLine1,
                            line2: (addressLine2 === undefined || addressLine2 === 'undefined' || addressLine2 === '' || addressLine2 == null) ? null : addressLine2,
                            postal_code: (postCode === undefined || postCode === 'undefined' || postCode === '' || postCode == null) ? null : postCode,
                            state: (state === undefined || state === 'undefined' || state === '' || state == null) ? null : state
                        },
                        email: (email === undefined || email === 'undefined' || email === '' || email == null) ? null : email,
                        name: (customerName === undefined || customerName === 'undefined' || customerName === '' || customerName == null) ? null : customerName,
                        phone: (phone === undefined || phone === 'undefined' || phone === '' || phone == null) ? null : phone
                    }
                }
            },
            redirect: isRedirect
        }

        // clearing the billing_details.address from the payment_method_data object for afterpay_clearpay payment method
        if (paymentMethod === properties.paymentMethod.afterPayClearPay) {
            confirmParam.confirmParams.payment_method_data.billing_details.address = {
                city: null,
                country: null,
                line1: null,
                line2: null,
                postal_code: null,
                state: null
            }
        }

        if (intentId.startsWith("pi_")) {
            try {
                if (paymentMethod === properties.paymentMethod.card && isFutureUsageSet) {
                    //setting the futureUsage in paymentIntent (for card payment method)
                    await fetch(`${baseURL}/v1/api/payment/operations/update`, {
                        method: properties.methodList.PUT,
                        body: JSON.stringify({ paymentIntentId: intentId }),
                        credentials: 'include',
                        headers: {
                            "Content-Type": properties.contentType.json,
                        }
                    });
                }
            }
            catch (e) {
                return setErrorMessage("Something went wrong, Please try again later");
            }
            ({ error } = await stripe.confirmPayment(confirmParam));
        }
        else {
            ({ error } = await stripe.confirmSetup(confirmParam));
        }
        if (error) {
            if (intentId.startsWith("pi_")) {
                if (!error.payment_intent) {
                    error.payment_intent = {
                        id: intentId
                    }
                }
            }
            else {
                if (!error.setup_intent) {
                    error.setup_intent = {
                        id: intentId
                    }
                }
            }
            if (!isTemplate) {
                post(inlineBaseURL + properties.apiList.setupIntentErrorEndpointURL, JSON.stringify(error), 'POST', false);
            }
            else {
                if (paymentReference !== undefined && paymentReference !== 'undefined' && paymentReference !== '' && paymentReference != null) {
                    setDeclineRateLimitCount(prev => prev + 1);
                    if ((declineRateLimitCount + 1).toString() === properties.decineRateLimit.toString()) {
                        post(baseURL + properties.apiList.setupIntentErrorEndpointURL, JSON.stringify(error), 'POST', false);
                    }
                    else {
                        error.message = "There was a problem in processing your payment";
                        setErrorMessage(error.message);
                        setShowCaptcha(true);
                        setShowSubmitBtn(true);
                        setIsLoading(false);
                        setCaptchaToken();
                        document.querySelector("#submit").classList.remove("hide");
                        return fetch(baseURL + properties.apiList.stripePaymentURL + '/' + intentId + '/' + paymentReference, {
                            method: 'GET',
                            headers: { 'Content-Type': 'application/json' },
                        })
                            .then(function (response) {
                                return response.json()
                            })
                            .then(function (data) {
                                console.log(data);
                            })
                            .catch((error) => {
                                console.error('Error:', error);
                            })
                    }
                }
                else {
                    post(baseURL + properties.apiList.setupIntentErrorEndpointURL, JSON.stringify(error), 'POST', false);
                }
            }
        }
        else {
            if (!isTemplate) {
                post(inlineBaseURL + "/v1/api/payment/response/paymentDetails", intentId, 'GET', true);
            }
            else {
                post(baseURL + "/v1/api/payment/response/paymentDetails", intentId, 'GET', true);
            }
        }
    }
    const handleVerifyCaptchaBackendFailure = (data) => {
        if (data["error-codes"]) {
            let error = data;
            if (intentId.startsWith("pi_")) {
                if (!error.payment_intent) {
                    error["payment_intent"] = { id: intentId }
                }
            }
            else {
                if (!error.setup_intent) {
                    error["setup_intent"] = { id: intentId }
                }
            }
            if (!isTemplate) {
                post(inlineBaseURL + properties.apiList.setupIntentErrorEndpointURL, JSON.stringify(error), 'POST', false);
            }
            else {
                post(baseURL + properties.apiList.setupIntentErrorEndpointURL, JSON.stringify(error), 'POST', false);
            }
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        setIsLoading(true);
        setErrorMessage("");
        document.querySelector("#submit").disabled = true;
        if (captchaToken && isCaptchaEnable && paymentMethod === properties.paymentMethod.card) {
            //for card payment method with captcha enabled
            document.querySelector(".cf-turnstile").remove();
            setShowTurnstileLoading(true);
            fetch(baseURL + properties.apiList.verifyCaptchaURL, {
                method: properties.methodList.POST,
                headers: { 'Content-Type': properties.contentType.json },
                body: JSON.stringify({ "token": captchaToken })
            }).then(function (response) {
                return response.json()
            }).then(async function (data) {
                console.log(data);
                setShowTurnstileLoading(false);
                if (data.success && (data["error-codes"].length === 0)) {
                    setShowTurnstileError(false);
                    document.querySelector("#submit").classList.add("hide");
                    document.querySelector("#verifyHumanText").classList.add("hide");
                    handleStripeSubmit();
                }
                else {
                    if ((data["error-codes"] && data["error-codes"].length > 0) || data.message) {
                        setShowTurnstileError(true);
                        handleVerifyCaptchaBackendFailure(data);
                    }
                }
            })
        }
        else if (!isCaptchaEnable || (isCaptchaEnable && paymentMethod !== properties.paymentMethod.card)) {
            setShowTurnstileError(false);
            document.querySelector("#submit").classList.add("hide");
            handleStripeSubmit();
        }
    };
    const onChangeHandler = (event) => {
        setPaymentMethod(event.value.type);
        if (event.complete && !event.empty) {
            if (isCaptchaEnable && paymentMethod === properties.paymentMethod.card) {
                var myVar1 = setInterval(myTimer, 500);
                function myTimer() {
                    if (document.getElementsByName("cf-turnstile-response")[0]) {
                        if (document.getElementsByName("cf-turnstile-response")[0].value) {
                            document.querySelector("#submit").classList.remove("hide");
                            setIsValid(true);
                            clearInterval(myVar1);
                        }
                    }
                }
            }
            else {
                setIsValid(true);
            }
        }
        else {
            setIsValid(false);
        }
    }

    let options = {
        fields: {
            billingDetails: {
                address: {
                    country: 'never',
                    postalCode: 'never'
                }
            }
        },
        wallets: {
            applePay: 'never',
            googlePay: 'never'
        },
        terms: {
            card: 'never'
        }
    }

    const handleOnLoad = () => {
        var onMessageHandler = function (e) {
            if (e.data === "success") {
                setShowSubmitBtn(true);
                if (isCaptchaEnable) {
                    setShowCaptcha(true);
                    setShowTurnstileLoading(true);
                }
                window.removeEventListener("message", onMessageHandler, false);
            }
        }
        //adding the event listener for message event
        window.addEventListener("message", onMessageHandler);
    }

    const translate = (str, locale) => {
        //getting the translation for "Submit" button text
        if (!locale || !languages[locale]) {
            locale = "auto";
        }
        return languages[locale][str] ? languages[locale][str] : str;
    }
    const onTurnstileCaptchaError = (error) => {
        if (captchaRetryAttemptCount >= 0) {
            if (captchaRetryAttemptCount === 0) {
                setShowTurnstileError(true);
                setShowTurnstileLoading(false);
                document.querySelector(".cf-turnstile").remove();
                document.querySelector("#submit").disabled = true;
                captchaRetryAttemptCount = -1;
            }
            else {
                captchaRetryAttemptCount--;
            }
        }
    }
    const onLoadTurnstileCaptcha = (widgetId) => {
        var varTurnstileLoading = setInterval(setIntervalTurnstileLoading, 5);
        function setIntervalTurnstileLoading() {
            if ((document.querySelector(".cf-turnstile")) && document.querySelector(".cf-turnstile").offsetHeight > 0) {
                setShowTurnstileLoading(false);
                clearInterval(varTurnstileLoading);
            }
            else {
                setShowTurnstileLoading(true);
            }
        }
    }
    useEffect(() => {
        handleOnLoad();
    }, []);

    return (
        <div>
            {paypalClientToken && <BraintreePayPalComponent isTemplate={isTemplate} amount={amount} paypalClientToken={paypalClientToken} currency={currency} intentType={intentType} region={region} email={email} paypalCustomerId={paypalCustomerId} />}
            <form onSubmit={handleSubmit}>
                <div id="stripePaymentFrame">
                    <PaymentElement id="paymentElement" onChange={(e) => onChangeHandler(e)} options={options} />
                    {paymentMethod === properties.paymentMethod.afterPayClearPay && (
                        <>
                            <h2 className='shippingAddressHeader'>Shipping Address</h2>
                            <AddressElement options={{ mode: 'shipping' }} onChange={(event) => setIsAddressCaptured(event.complete)} />
                        </>
                    )}
                    {showSubmitBtn && (
                        (<>
                            {(showCaptcha && isCaptchaEnable && paymentMethod === properties.paymentMethod.card) && (
                                <div id="turnstile-captcha-container">
                                    <h3 id="verifyHumanText">Verifying you are a human.</h3>
                                    <Turnstile className="cf-turnstile" sitekey={turnstileSiteKey} action={captchaAction} language={locale} onVerify={(token) => setCaptchaToken(token)} onError={(error) => onTurnstileCaptchaError(error)} onLoad={(widgetId) => onLoadTurnstileCaptcha(widgetId)} />
                                    {showTurnstileLoading && (<div id="turnstile-loading" className="spinner"></div>)}
                                    {showTurnstileError && (<div id="turnstile-error">There was an problem processing your payment</div>)}
                                </div>
                            )}
                            <button disabled={isLoading || !stripe || !elements || !isValid || showTurnstileLoading || showTurnstileError || (isCaptchaEnable && !captchaToken && paymentMethod === properties.paymentMethod.card) || (!isAddressCaptured && paymentMethod === properties.paymentMethod.afterPayClearPay)} id="submit" className={`${isTemplate ? "" : "isEmbedded"}`}>
                                <span id="button-text">
                                    {isLoading ? <div className="spinner" id="spinner"></div> : translate(`${properties.Submit}`, locale)}
                                </span>
                            </button>
                            {errorMessage && <div className="errorMsg">{errorMessage}</div>}
                        </>)
                    )}
                    {/* {errorMessage && <div className="errorMsg">{errorMessage}</div>} */}
                </div>
            </form>
        </div>
    )
};

export default ResponseStripeIframe;