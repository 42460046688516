import { useState, useEffect } from "react";
import braintree from "braintree-web";
import { properties } from "./../assets/properties";
import { env } from "../env";

const BraintreePayPalComponent = ({
  intentType,
  paypalClientToken,
  amount,
  currency,
  isTemplate,
  paypalCustomerId,
}) => {
  const baseURL = env.REACT_APP_BASE_URL;
  const inlineBaseURL = env.REACT_APP_INLINE_BASE_URL;
  const [braintreeInstance, setBraintreeInstance] = useState(null);
  const [paypalSDKLoaded, setPaypalSDKLoaded] = useState(false);

  const formPost = (
    path,
    paymentNonce,
    paymentError,
    method = "post",
    isSuccess
  ) => {
    const createHiddenInputField = (fieldName, fieldValue) => {
      const inputField = document.createElement("input");
      inputField.type = "hidden";
      inputField.name = fieldName;
      inputField.value = fieldValue;
      return inputField;
    };
    const form = document.createElement("form");
    form.method = method;
    if (isTemplate) {
      form.target = "_parent";
    } else {
      form.target = "_self";
    }
    form.action = path;
    if (!isSuccess) {
      form.appendChild(createHiddenInputField("paymentError", paymentError));
    } else {
      form.appendChild(createHiddenInputField("paymentNonce", paymentNonce));
    }
    form.appendChild(createHiddenInputField("customerId", paypalCustomerId));
    document.body.appendChild(form);
    form.submit();
  };
  useEffect(() => {
    const createBraintreeClient = () => {
      braintree.client.create(
        {
          authorization: paypalClientToken,
        },
        (clientErr, clientInstance) => {
          if (clientErr) {
            console.error(`Error creating Braintree Client: ${clientErr}`);
            return;
          }
          braintree.paypalCheckout.create(
            {
              client: clientInstance,
            },
            (paypalCheckoutErr, paypalCheckoutInstance) => {
              if (paypalCheckoutErr) {
                console.error(
                  `Error creating PayPal Checkout: ${paypalCheckoutErr}`
                );
                return;
              }
              setBraintreeInstance(paypalCheckoutInstance);
              const loadSDKOptions = { currency };
              if (properties.paypalProperties.supportedVaultOperations.includes(intentType)) {
                loadSDKOptions.vault = true;
              } else {
                loadSDKOptions.intent =
                  properties.paypalProperties.intentTypes[intentType];
              }
              paypalCheckoutInstance.loadPayPalSDK(loadSDKOptions, () => {
                setPaypalSDKLoaded(true);
              });
            }
          );
        }
      );
    };
    if (properties.paypalProperties.supportedOperations.includes(intentType)) {
      createBraintreeClient();
    }
  }, []);

  useEffect(() => {
    if (paypalSDKLoaded) {
      window.paypal
        .Buttons({
          fundingSource: window.paypal.FUNDING.PAYPAL,
          ...(properties.paypalProperties.supportedVaultOperations.includes(intentType)
            ? {
                createBillingAgreement: function () {
                  return braintreeInstance.createPayment({
                    flow: properties.paypalProperties.flowTypes.vault,
                    enableShippingAddress: true,
                    shippingAddressEditable: false,
                  });
                },
              }
            : {
                createOrder: function () {
                  return braintreeInstance.createPayment({
                    intent: properties.paypalProperties.intentTypes[intentType],
                    flow: properties.paypalProperties.flowTypes.checkout,
                    currency: currency,
                    amount: amount,
                    requestBillingAgreement: true,
                  });
                },
              }),
          onApprove: (data, actions) => {
            return braintreeInstance.tokenizePayment(data, (err, payload) => {
              if (err) {
                formPost(
                  baseURL + `${properties.apiList.paypalResponseURL}/error`,
                  null,
                  JSON.stringify(err),
                  properties.methodList.POST,
                  false
                );
              } else {
                formPost(
                  baseURL + `${properties.apiList.paypalResponseURL}/paymentDetails`,
                  payload.nonce,
                  null,
                  properties.methodList.GET,
                  true
                );
              }
            });
          },
          onCancel: (data) => {
          },
          onError: (err) => {
            formPost(
              baseURL + `${properties.apiList.paypalResponseURL}/error`,
              null,
              JSON.stringify(err),
              properties.methodList.POST,
              false
            );
          },
        })
        .render("#paypal-button-container")
        .then(function () {});
    }
  }, [paypalSDKLoaded]);

  return <div id="paypal-button-container"></div>;
};

export default BraintreePayPalComponent;
