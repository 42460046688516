import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import InnerHTML from 'dangerously-set-html-content';
import Response from './components/Response';
import ResponseStripeIframe from "./components/ResponseStripeIframe";
import { properties } from './assets/properties';
import './App.css';
import './css/template.css';
import { env } from './env';

function App() {
  //setting up the state variables
  const [stripePromise, setStripePromise] = useState();
  const [isJSONResponse, setIsJSONResponse] = useState(true);
  const [responsedata, setResponseData] = useState();
  const [clientSecret, setClientSecret] = useState("");
  const [captchaAction, setCaptchaAction] = useState("");
  const [isTemplate, setIsTemplate] = useState();
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [postCode, setPostCode] = useState("");
  const [country, setCountry] = useState("");
  const [returnUrl, setReturnUrl] = useState("");
  const [intentId, setIntentId] = useState("");
  const [region, setRegion] = useState("");
  const [currency, setCurrency] = useState("");
  const [reference, setReference] = useState();
  const [locale, setLocale] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [email, setEmail] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [phone, setPhone] = useState("");
  const [paymentReference, setPaymentReference] = useState("");
  const [errorMsg, setErrorMsg] = useState();
  const [isFutureUsageSet, setFutureUsage] = useState(false);
  const [paypalClientToken, setPaypalClientToken] = useState("");
  const [intentType, setintentType] = useState("");
  const [amount, setAmount] = useState("");
  const [paypalCustomerId, setPaypalCustomerId] = useState("");
  var referenceParam;

  useEffect(() => {
    const getClientSecret = async () => {
      try {
        if (window.location.pathname === '/') {
          var request = {
            method: properties.methodList.GET,
            headers: new Headers({ 'Content-type': properties.contentType.formUrlEncoded },),
            credentials: "include"
          }
          let baseURL = (window !== window.parent) ? env.REACT_APP_INLINE_BASE_URL : env.REACT_APP_BASE_URL;
          if (window !== window.parent) {
            let urlParam = new URLSearchParams(window.location.search);
            referenceParam = urlParam.get("reference");
            setReference(referenceParam);
          }
          fetch(baseURL + (properties.apiList.templateURL + (window !== window.parent ? ("?ref=" + referenceParam) : "")), request, true)
            .then(response => {
              const contentType = response.headers.get("content-type");
              if (contentType && contentType.indexOf(properties.contentType.json) !== -1) {
                return response.json().then(responsedata => {
                  if (responsedata.returnCode) {
                    setErrorMsg(responsedata.returnMessage);
                  }
                  else {
                    setClientSecret(responsedata.clientSecret);
                    setCaptchaAction(responsedata.appShortName);
                    setResponseData(responsedata);
                    setStripePromise(loadStripe(responsedata.publishableKey));
                    setIsJSONResponse(true);
                  }
                });
              } else {
                return response.text().then(responsedata => {
                  setIsJSONResponse(false);
                  setResponseData(responsedata);
                });
              }
            });
        }
        else if (window.location.pathname === '/ResponseStripeIframe') {
          let urlParam = new URLSearchParams(window.location.search);
          // collecting the query-Search Paramters and setting the corresponding states
          const { isTemplate, appShortName, clientSecret, publishableKey, addressLine1, addressLine2, postCode, country, returnUrl, intentId, region, currency, reference, locale, city, state, email, customerName, phone, paymentReference, isFutureUsageSet, amount, paypalClientToken, intentType, paypalCustomerId } = Object.fromEntries(urlParam);
          setClientSecret(clientSecret);
          setCaptchaAction(appShortName);
          setIsTemplate(isTemplate);
          setStripePromise(loadStripe(publishableKey));
          setAddressLine1(addressLine1);
          setAddressLine2(addressLine2);
          setPostCode(postCode);
          setCountry(country);
          setReturnUrl(returnUrl);
          setIntentId(intentId);
          setRegion(region);
          setCurrency(currency);
          setReference(reference);
          setLocale(locale);
          setCity(city);
          setState(state);
          setEmail(email);
          setCustomerName(customerName);
          setPhone(phone);
          setPaymentReference(paymentReference);
          setResponseData({});
          setFutureUsage(isFutureUsageSet);
          setAmount(amount);
          setPaypalClientToken(paypalClientToken);
          setintentType(intentType);
          setPaypalCustomerId(paypalCustomerId);
        }
        else {
          const clientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret');
          setClientSecret(clientSecret);
        }
      } catch (e) {
        console.log('Error Occurs');
        console.log(e);
      }
    }
    // fetching the ClientSecret and Template
    getClientSecret();
  }, []);

  const options = {
    clientSecret,
    appearance: { theme: properties.theme },
    locale: isTemplate ? "auto" : locale
  };

  if (isJSONResponse) {
    return (
      <div className="App">
        {(clientSecret && responsedata && stripePromise) && (
          <Elements stripe={stripePromise} options={options}>
            <Router>
              <Routes>
                <Route path="/" element={<Response responsedata={responsedata} errorMsg={null} reference={reference} />} />
                <Route path={properties.urlList.ResponseStripeIframe} element={<ResponseStripeIframe isTemplate={isTemplate} captchaAction={captchaAction} clientSecret={clientSecret} addressLine1={addressLine1} addressLine2={addressLine2} postCode={postCode} country={country} returnUrl={returnUrl} intentId={intentId} region={region} currency={currency} reference={reference} locale={locale} city={city} state={state} email={email} customerName={customerName} phone={phone} paymentReference={paymentReference} isFutureUsageSet={isFutureUsageSet} paypalClientToken={paypalClientToken} intentType={intentType} amount={amount} paypalCustomerId={paypalCustomerId}/>} />
              </Routes>
            </Router>
          </Elements>
        )}
        {errorMsg && (
          <Router>
            <Routes>
              <Route path="/" element={<Response responsedata={responsedata} errorMsg={errorMsg} />} />
            </Routes>
          </Router>
        )}
      </div>
    );
  }
  else {
    return (<div><InnerHTML html={responsedata} /></div>)
  }
}

export default App;