export const properties = {
    urlList: {
        ResponseStripeIframe: "/ResponseStripeIframe"
    },
    apiList: {
        templateURL: "/v1/clients/templates",
        getClientSecretURL: "",
        setupIntentErrorEndpointURL: "/v1/api/payment/response/error",
        verifyCaptchaURL: "/v1/verify/captcha",
        stripePaymentURL: "/v1/stripePayment",
        paypalResponseURL: "/v1/api/paypal/response"
    },
    contentType: {
        formUrlEncoded: "application/x-www-form-urlencoded",
        json: "application/json",
    },
    methodList: {
        POST: "post",
        GET: "get",
        PUT: "put"
    },
    intentErrorHiddenKey: "intentError",
    Submit: "Submit",
    theme: "stripe",
    decineRateLimit: 3,
    paymentMethod: {
      card: "card",
      afterPayClearPay: "afterpay_clearpay",
    },
    paypalProperties: {
        supportedVaultOperations: ['validate', 'tokenise'],
        intentTypes: {
            validate: 'validate',
            tokenise: 'validate',
            auth: 'authorize',
            authsettle: 'capture'
        },
        supportedOperations: ['validate', 'tokenise', 'auth', 'authsettle'],
        flowTypes: {
            vault: 'vault',
            checkout: 'checkout'
        }
    }
};