import React, { useEffect } from 'react';
import InnerHTML from 'dangerously-set-html-content';
import { properties } from '../assets/properties';

function Response({ responsedata, errorMsg, reference }) {
    const { template, appShortName, clientSecret, addressLine1, addressLine2, postCode, country, returnUrl, intentId, region, currency, publishableKey, locale, city, state, email, customerName, phone, paymentReference, setupFutureUsage, amount, paypalClientToken, intentType, paypalCustomerId } = responsedata || {};
    const origin = window.location.origin;
    const encodedAddressLine1 = (addressLine1 ? encodeURIComponent(addressLine1).replace(/[!'()*]/g, escape) : addressLine1);
    const encodedAddressLine2 = (addressLine2 ? encodeURIComponent(addressLine2).replace(/[!'()*]/g, escape) : addressLine2);
    const encodedPostCode = (postCode ? encodeURIComponent(postCode).replace(/[!'()*]/g, escape) : postCode);
    const encodedCountry = (country ? encodeURIComponent(country).replace(/[!'()*]/g, escape) : country);
    const encodedRegion = (region ? encodeURIComponent(region).replace(/[!'()*]/g, escape) : region);
    const encodedCurrency = (currency ? encodeURIComponent(currency).replace(/[!'()*]/g, escape) : currency);
    const encodedLocale = (locale ? encodeURIComponent(locale).replace(/[!'()*]/g, escape) : locale);
    const encodedEmail = (email ? encodeURIComponent(email).replace(/[!'()*]/g, escape) : email);
    const encodedCity = (city ? encodeURIComponent(city).replace(/[!'()*]/g, escape) : city);
    const encodedState = (state ? encodeURIComponent(state).replace(/[!'()*]/g, escape) : state);
    const encodedCustomerName = (customerName ? encodeURIComponent(customerName).replace(/[!'()*]/g, escape) : customerName);
    const encodedPhone = (phone ? encodeURIComponent(phone).replace(/[!'()*]/g, escape) : phone);

    useEffect(() => {
        if (!errorMsg) {
            if (template && clientSecret) {
                document.getElementById("wpg-iframe").innerHTML = "<div id='loadingDiv'></div><iframe id='tokenFrame' name='tokenFrame' title='Stripe iframe' src='" + origin + properties.urlList.ResponseStripeIframe + "?clientSecret=" + clientSecret + "&appShortName=" + appShortName + "&publishableKey=" + publishableKey + "&addressLine1=" + encodedAddressLine1 + "&addressLine2=" + encodedAddressLine2 + "&postCode=" + encodedPostCode + "&country=" + encodedCountry + "&returnUrl=" + returnUrl + "&intentId=" + intentId + "&region=" + encodedRegion + "&city=" + encodedCity + "&state=" + encodedState + "&email=" + encodedEmail + "&customerName=" + encodedCustomerName + "&phone=" + encodedPhone + "&currency=" + encodedCurrency + "&isTemplate=" + true + "&paymentReference=" + paymentReference + "&isFutureUsageSet=" + setupFutureUsage + `&amount=${amount}` + `&paypalClientToken=${paypalClientToken}` + `&intentType=${intentType}` + `&paypalCustomerId=${paypalCustomerId}` + "'/>}";
            }
            else if (!template && clientSecret) {
                document.getElementById("wpg-iframe").innerHTML = "<div id='loadingDiv'></div><iframe id='tokenFrame' name='tokenFrame' title='Stripe iframe' src='" + origin + properties.urlList.ResponseStripeIframe + "?clientSecret=" + clientSecret + "&appShortName=" + appShortName + "&publishableKey=" + publishableKey + "&addressLine1=" + encodedAddressLine1 + "&addressLine2=" + encodedAddressLine2 + "&postCode=" + encodedPostCode + "&country=" + encodedCountry + "&returnUrl=" + returnUrl + "&intentId=" + intentId + "&region=" + encodedRegion + "&city=" + encodedCity + "&state=" + encodedState + "&email=" + encodedEmail + "&customerName=" + encodedCustomerName + "&phone=" + encodedPhone + "&currency=" + encodedCurrency + "&locale=" + encodedLocale + "&reference=" + reference + "&paymentReference=" + paymentReference + "&isFutureUsageSet=" + setupFutureUsage + `&amount=${amount}` + `&paypalClientToken=${paypalClientToken}` + `&intentType=${intentType}` + `&paypalCustomerId=${paypalCustomerId}` + "'/>}";
            }
            var iframe = document.getElementById('tokenFrame');

            var _timer = setInterval(function () {
                var doc = iframe.contentDocument || iframe.contentWindow;
                setTimeout(function () {
                    if (doc.document) doc = doc.document;
                    if (doc.readyState && doc.readyState === 'complete') {
                        //setting the StripeIframe Height
                        setStripeIframeHeight();
                        clearInterval(_timer);
                        var parentFrame = document.getElementById("wpg-iframe");
                        var loadingDiv = document.getElementById('loadingDiv');
                        document.getElementById("tokenFrame").contentWindow.postMessage("success", "*");
                        if (loadingDiv) {
                            parentFrame.removeChild(loadingDiv);
                        }
                    }
                }, 100);
            }, 1000);

            // document.getElementById("tokenFrame").onload = function () {
            //     var parentFrame = document.getElementById("wpg-iframe");
            //     var loadingDiv = document.getElementById('loadingDiv');
            //     document.getElementById("tokenFrame").contentWindow.postMessage("success", "*");
            //     if (loadingDiv) {
            //         parentFrame.removeChild(loadingDiv);
            //     }
            // };
        }
    }, [template && clientSecret]);

    const setStripeIframeHeight = () => {
        setInterval(() => {
            const tokenFrame = document.querySelector("#tokenFrame");
            const innerDoc = tokenFrame.contentDocument || tokenFrame.contentWindow.document;
            const stripePaymentFrame = innerDoc.getElementById("stripePaymentFrame");
            innerDoc.querySelector("html").style.overflowY = "hidden";
            const rect = stripePaymentFrame.getBoundingClientRect();
            const heightWpgIframe = rect.bottom - rect.top;
            document.getElementById("wpg-iframe").style.height = heightWpgIframe + 60 + "px";
        }, 500);
    }

    return (
        <div>
            {!errorMsg ? (
                template ?
                    (<div><InnerHTML html={template} /></div>) : (<div id="wpg-iframe"></div>)
            ) : (<div className="errorMsg">{errorMsg}</div>)}
        </div>
    )
}

export default Response;